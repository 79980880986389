import React, { useEffect, useState } from 'react';

// ANTD
import Button from 'antd/es/button';
import { CalendarOutlined } from '@ant-design/icons';

// COMPONENTS
import Ready from './shared/Ready/Ready';

// STYLES
import './App.scss';

// SVGS
import Somnia from './assets/img/somnia.svg';

type EventStatus = "PREVIOUS" | "UPCOMING";

export interface Event {
  id: string;
  img: string;
  title: string;
  platform: string;
  powered: string;
  date: string;
  time?: string;
  intro: string;
  url: string;
  status: EventStatus;
}

export const events: Event[] = [
  {
    id: "1",
    img: "improbable-labs.jpg",
    title: "Chasing Alpha",
    platform: "Chasing Alpha",
    powered: "Powered by MSquared",
    date: "2023-09-18",
    intro: "Chasing Alpha, took place on September 14, 2003, and it was a blast! It featured a lively exchange between <a href='https://twitter.com/BoredElonMusk'>@BoredElonMusk</a> and <a href='https://twitter.com/HermanNarula'>@HermanNarula</a>, delving into the future of the metaverse and video games. Only they were giant avatars in the metaverse, fending off hordes of eager players racing to get up close and personal to rack up precious points. Not your normal panel discussion, then.",
    url: "",
    status: "PREVIOUS"
  },
  {
    id: "2",
    img: "virtual-ballpark.jpg",
    title: "Virtual Ballpark",
    platform: "Virtual Ballpark",
    powered: "Powered by MSquared",
    date: "2023-09-22",
    intro: "On July 8, 2003, the all-new MLB Virtual Ballpark launched with a charity match watch party where fans got a first look at some of the Ballpark’s features and components. On September 20, the stakes were raised as the Virtual Ballpark streamed its first ever regular season game in the metaverse. Concurrently, fans could watch a real-time 3D representation of the action right from the edge of the field, chat, and participate in a scavenger hunt.",
    url: "",
    status: "PREVIOUS"
  },
  {
    id: "3",
    img: "victory-league.jpg",
    title: "Victory League",
    platform: "Victory League",
    powered: "Powered by MSquared",
    date: "2023-10-18",
    intro: "On October 18 2023, Victory League event Clash of Fans invited Arsenal and Chelsea fans to a virtual event of contained chaos. Robbie Lyle and co-host <a href='https://twitter.com/matissearmani'>@matissearmani</a> brought the banter as fans tackled rampaging mascots, giant lemons, sort-of Rocket League (if cars were replaced by hordes of fans) and interactive quizzes.",
    url: "",
    status: "PREVIOUS"
  },
  {
    id: "4",
    img: "mlb-nlcs.jpg",
    title: "MLB NLCS Game 4",
    platform: "MLB",
    powered: "Powered by MSquared",
    date: "2023-10-20",
    intro: "The third Virtual Ballpark event again hit it out of the park. A post-season game on October, 20 2023, saw the Arizona Diamondbacks face off against the Philadelphia Phillies in game 4 of the NLCS. Fans got to personalise avatars, hang out with friends, explore the Virtual Ballpark, and watch a cool 3D version of the game from different angles that was synced to a live broadcast.",
    url: "",
    status: "PREVIOUS"
  },
  {
    id: "5",
    img: "edison-night-run.jpg",
    title: "Edison - Night Run",
    platform: "Edison",
    powered: "Powered by MSquared",
    date: "2023-10-31",
    intro: "Gamevents are one-shot events where a whole bunch of people play a game-like experience, with a smattering of socialisation. This particular one, which took place on October, 31, 2023 found us partnering with the Wizards to quickly conjure up a fantasy realm of gigantic mazes and puzzles for them to play in.",
    url: "",
    status: "PREVIOUS"
  },
  {
    id: "6",
    img: "edison-jump.jpg",
    title: "Edison - Jump",
    platform: "Edison",
    powered: "Powered by MSquared",
    date: "2023-11-16",
    intro: "The second Edison ‘gamevent’, Jump, was a blast, joining forces with Real Third Web founder Jonah Blake to turn his brand into a live experience. This was a wild mix of endless runner and gameshow, with a track that kept changing and evolving.",
    url: "",
    status: "PREVIOUS"
  },
  {
    id: "7",
    img: "edison-snow-crush.jpg",
    title: "Edison - Snowcrush",
    platform: "Edison",
    powered: "Powered by MSquared",
    date: "2023-12-13",
    intro: "The third Edison event, just before the Christmas holidays, was more chill than its predecessors. Instead of intense races for individual glory, Snowcrush had a relaxed vibe, inviting players to construct epic gingerbread houses atop a frozen lake in a blocky, wintry wonderland.",
    url: "",
    status: "PREVIOUS"
  },
  {
    id: "8",
    img: "dawkins-bulldome.jpg",
    title: "Dawkins - Bulldome",
    platform: "Edison",
    powered: "Powered by MSquared",
    date: "2024-01-10",
    intro: "Bulldome, was all about testing new formats with streamers and players, to see if they appealed – and they did! In ‘The Glow,’ an evolved Chasing Alpha, everyone tried to stick close to the opposing team’s boss without being nuked back to spawn points. Next, ‘Shadowchaser’ combined stealth and chase action as ‘shadows’ hunted down and converted ‘light’ players to their team.",
    url: "",
    status: "PREVIOUS"
  },
  {
    id: "8",
    img: "edison-hotshots.jpg",
    title: "Edison - Hotshots",
    platform: "Edison",
    powered: "Powered by MSquared",
    date: "2024-02-1",
    intro: "“Hotshots,” an Edison collab with Real Third Web founder Jonah Blake, reimagined basketball as a chaotic, multiplayer brawl in the metaverse. Hotshots really was like no other basketball game in history. Two frantic five-minute rounds. 140 players, split into two teams. Everyone battling it out on a court strewn with hoops, obstacles and jump pads. All fuelled by hosts urging their teams on and the promise of epic prizes, including an NBA Jam arcade cabinet and Chapter 2 phones.",
    url: "",
    status: "PREVIOUS"
  },
  {
    id: "9",
    img: "dawkins-ice-breaker.jpg",
    title: "Dawkins - Ice Breaker",
    platform: "Dawkins",
    powered: "Powered by MSquared",
    date: "2024-02-02",
    intro: "In Ice Breaker, players soared through the air in precarious ice bubbles, battling with weapons and collisions to eliminate opponents. If a player’s bubble bursts, a well-timed shot from the ground lets them regain their place in the sky.",
    url: "",
    status: "PREVIOUS"
  },
  {
    id: "10",
    img: "kosmopop.jpg",
    title: "Twice listening party",
    platform: "KosmoPop",
    powered: "Powered by MSquared",
    date: "2024-02-24",
    intro: "Music is a perfect fit for the metaverse, as we have seen from recent events like the Twice K-pop listening party in KosmoPop, a thriving virtual space for K-pop fans to connect, celebrate their idols and experience spectacular events. The debut event was a listening party for Twice’s new EP, where fans could chat on the KosmoPop website, while listening to the new music.",
    url: "",
    status: "PREVIOUS"
  },
  {
    id: "11",
    img: "edison-npc-snatchers.jpg",
    title: "Edison - NPC Snatchers",
    platform: "Edison",
    powered: "Powered by MSquared",
    date: "2024-03-14",
    intro: "Edison’s brand-new format found players in Edison City, bustling with NPCs. Players teamed up with aliens to round-up the NPCs and lead them to extraction points, where they were beamed up to UFOs. The fun was further amped up by players stealing each-other’s NPCs – and trying to not also lose them to boomboxes. (NPCs can’t resist a good beat!) Two six-minute rounds of frenetic chaos, then – doubly so, given that players could deck out avatars in weird swag, thanks to our vending machines.",
    url: "",
    status: "PREVIOUS"
  },
  {
    id: "12",
    img: "somnia-first-metaverse.png",
    title: "Somniaverse - Episode 2",
    platform: "Indus",
    powered: "Powered by MSquared",
    date: "2024-05-02",
    time: "8:30 AM EST",
    intro: "Be a part of establishing the virtual society with Somnia at our metaverse AMA session, hosted with Indus, a platform for facilitating immersive digital experiences such as conventions, exhibitions, and networking events. In this AMA the Somnia team will reflect on the success of our massive Betanet launch and look forward to our Testnet and upcoming Metaverse Browser. We’ll also be there to answer questions from the community. Join Somnia for our first AMA in the metaverse!",
    url: "https://indus.m2worlds.io",
    status: "PREVIOUS"
  },
  {
    id: "13",
    img: "space-race.jpg",
    title: "Space Race",
    platform: "Edison",
    powered: "Powered by Edison",
    date: "2024-05-23",
    time: "8am EST",
    intro: "Welcome to the Space Race, an extraordinary contest where you will harness otherworldly abilities to soar through the vast expanse that bridges the universe of LVCIDIA and Edison! Reject the shackles of “gravity” behind as you take flight, using your body to navigate celestial obstacles and outpace your rivals. Engage in high-speed aerial challenges, showcasing your mastery of flight and unleashing awe-inspiring skills against your opponents to obtain the fastest times in the galaxy.",
    url: "http://bit.ly/SomniaSpaceRace",
    status: "PREVIOUS"
  },
  {
    id: "14",
    img: "kosmo-pop-event.png",
    title: "AleXa concert takeover",
    platform: "MSquared",
    powered: "Powered by MSquared",
    date: "2024-05-25",
    time: "10pm EST",
    intro: "AleXa will be hosting a concert at the newly launched KosmoPop club. The club is an ‘always on’ home for K-Pop fans where the AleXa concert takeover will be the first of many.",
    url: "https://bit.ly/SomniaKosmopop",
    status: "PREVIOUS"
  },
  /*
  {
    id: "15",
    img: "victory-league-2.jpg",
    title: "Victory League",
    platform: "MSquared",
    powered: "Powered by MSquared",
    date: "2024-06-05",
    time: "2:15 pm EST",
    intro: "Step into the metaverse for the first episode of “Beyond the Pitch,” a Victory League event, calling the Somnia community, featuring a live Q&A with Arsenal and Ukraine national team star, Alex Zinchenko. This is your chance to interact with Alex Zinchenko, ask your burning questions, and gain exclusive insights into his life both on and off the field.",
    url: "https://bit.ly/VictoryLeagueSomnia",
    status: "UPCOMING"
  }
  */
  {
    id: "16",
    img: "somnia-verse.png",
    title: "Title Somniaverse Episode 3 on Indus",
    platform: "MSquared",
    powered: "Powered by MSquared",
    date: "2024-06-13",
    time: "8am EST",
    intro: "The Somnia team has had a huge month filled with exciting product releases, partnership announcements and important milestones that we can’t wait to share with you! We will be returning to the Indus metaverse for another Somniaverse AMA where we review our accomplishments for the month and answer questions from the community. In this episode of our AMA, we’ll be talking about our Metaverse Browser, the events that we have facilitated both in the metaverse and the physical world, and the major partnerships that we have announced. Join us for another Somnia AMA in the metaverse!",
    url: "https://indus.m2worlds.io",
    status: "PREVIOUS"
  },
  {
    id: "17",
    img: "city-shots.png",
    title: "City Shots",
    platform: "Edison",
    powered: "Powered by Edison",
    date: "2024-06-28",
    time: "12:00 PDT 3:00 PM EST",
    intro: "Edison is a unique blend of a social network, games platform and streaming platform focused around large scale Massive Multiplayer Events. It’s designed to be a fun “third space” for internet culture and internet celebrities to gather en-masse to play, celebrate, create and socialise. The Somnia community will be gathering in Edison, featuring Edison’s premier event Cityshots, a chaotic, multiplayer basketball brawl in the metaverse!",
    url: "https://bit.ly/SomniaCityshots",
    status: "PREVIOUS"
  },
  {
    id: "18",
    img: "road-trip.png",
    title: "Yield Guide Games: Roadtrip",
    platform: "Edison",
    powered: "Powered by Edison",
    date: "2024-07-12",
    time: "20:00 BST",
    intro: "Join us in Edison City with our partners YGG to celebrate the end of their roadtrip. We will be live-streaming their real-world event in the Edison Metaverse! Alongside the live-stream, we have a variety of time trial challenges to take part in. Compete with other players to get the best leaderboard time. Looking forward to seeing you all in the Edison City!",
    url: "https://edison.m2worlds.io/",
    status: "PREVIOUS"
  },
  {
    id: "19",
    img: "summercrush.png",
    title: "Summercrush",
    platform: "Edison",
    powered: "Powered by Edison",
    date: "2024-08-22",
    time: "9AM EST",
    intro: "Block off time this summer to construct the biggest, weirdest, more beautiful structures in the metaverse. SUMMERCRUSH is our latest experience that will allow YOU to create the world that thousands of friends can enjoy. Dip your toes in the sand, grab a tropical cocktail or ten, and get to building.",
    url: "https://bit.ly/Somnia-Edison-SummerCrush",
    status: "PREVIOUS"
  }
];

function App() {

  const [eventStatusState, setEventStatusState] = useState<EventStatus>("PREVIOUS");
  const [eventsListState, setEventsListState] = useState<Event[]>([]);

  const setEventsList = (): void => {
    setEventsListState(events.filter((event: Event) => event.status === eventStatusState).reverse());
  };

  const redirect = (url: string, blank?: string): void => {
    window.open(url, blank);
  }

  useEffect(() => {
    //redirect("https://betanet.somnia.network/events", "_self");
    setEventsList();
  }, [eventStatusState]); 

  return (
    <>
      <article className="events-container">
        <div className="somnia-logo-container">
          <img src={Somnia} alt="Somnia"/>
        </div>
        <h1>Experiences on Somnia</h1>
        <div className="events-selector">
          <Button className={"event-selector-btn " + (eventStatusState === "PREVIOUS" ? "active" : "")} type="primary" size="large" onClick={() => setEventStatusState("PREVIOUS")}>
            Previous Experiences
          </Button>
          <Button className={"event-selector-btn " + (eventStatusState === "UPCOMING" ? "active" : "")} type="primary" size="large" onClick={() => setEventStatusState("UPCOMING")}>
            Upcoming Experiences
          </Button>
          <Button className="event-selector-btn" type="primary" size="large">
            <span className="t-upcoming-exp" onClick={() => window.open("https://lu.ma/Somnia", "_blank")}>Offline Events</span>
          </Button>
        </div>
        <div className="events-content">
          {eventsListState.length ? (eventsListState.map((event: Event, i: number) => (
            <div className="event-item-container" key={i} onClick={() => event.url && redirect(event.url)}>
              <div className="event-content">
                <div className="event-image">
                  <img src={"./assets/img/" + event.img} alt={event.title}/>
                </div>
                <div className="event-info">
                  <h2>{event.title} <span className="date"><CalendarOutlined />{new Date(event.date).toDateString()}{event.time ? <span> - {event.time}</span>: null}</span></h2>
                  <h5>{event.platform}<span className="circle">&#9679;</span><span className="powered">{event.powered}</span></h5>
                </div>
                <div className="event-intro" dangerouslySetInnerHTML={{__html: event.intro}}></div>
              </div>
            </div>
          ))) : <h3>More mega events coming soon!</h3>}
        </div>
      </article>
      <Ready/>
    </>
  );
}

export default App;
