import React from 'react';

// COMPONENTS
import Divider from '../../components/Divider/Divider';
import SocialNetwork from '../../components/SocialNetwork/SocialNetwork';

// STYLES
import './Ready.scss';

function Ready() {
    return (
        <footer className="ready-container">
            <p>Your journey through the open metaverse begins here, and your feedback will shape the future of our universe</p>
            <h2>Ready to be part<br/>of the <span>adventure</span>?</h2>
            <Divider color="#4141ff" line={true}/>
            <SocialNetwork/>
      </footer>
    );
}

export default Ready;