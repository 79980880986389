import React from 'react';

// STYLES
import './SocialNetwork.scss';

// SVG
import ArrowDiagonal from '../../assets/img/arrow-diagonal.svg'

const SocialNetwork = () =>{

    const redirect = (url: string): void => {
        window.open(url, "_blank");
    }

    return (
        <div className="social-network-container">
            <div className="social-network-item social-twitter-events events" onClick={() => redirect("https://twitter.com/Somnia_Network")}>Twitter<img src={ArrowDiagonal}/></div>
            <div className="social-network-item social-discord-events events" onClick={() => redirect("http://discord.gg/Somnia")}>Discord<img src={ArrowDiagonal}/></div>
            <div className="social-network-item social-telegram-events events" onClick={() => redirect("http://t.me/somnianetwork")}>Telegram<img src={ArrowDiagonal}/></div>
            <div className="social-network-item social-reddit-events events" onClick={() => redirect("https://www.reddit.com/r/SomniaNetwork/")}>Reddit<img src={ArrowDiagonal}/></div>
        </div>
    );

};

export default SocialNetwork;