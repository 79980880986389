import React from 'react';

// STYLES
import './SomniaIcon.scss';

const SomniaIcon = ({ color }: { color: string }) => {

    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.8455 0.0163701C16.7237 0.0302283 16.3088 0.0768155 15.9234 0.120012C12.7846 0.471554 9.64533 1.7331 7.07177 3.67708C6.16601 4.3613 4.69928 5.78493 3.97824 6.67982C2.45589 8.56897 1.33644 10.7009 0.692186 12.9377C0.119628 14.9255 -0.0447182 16.2715 0.00986734 18.5259C0.0530195 20.3077 0.201062 21.3642 0.606028 22.7793L0.838902 23.5932L1.28134 23.5686C1.98461 23.5295 3.58699 23.2129 4.5353 22.9257C5.73169 22.5633 6.5771 22.2285 7.61438 21.7062C9.52635 20.7436 10.8689 19.7655 12.4584 18.1771C14.1325 16.5042 15.025 15.2573 16.0737 13.1258C17.2397 10.7561 17.8118 8.44277 17.956 5.51514L18.0077 4.46494L18.3259 4.98071C18.5009 5.26437 18.8016 5.70378 18.9941 5.95713C19.5449 6.68196 20.9841 8.06697 21.7324 8.59241C23.2736 9.67453 25.2103 10.4859 27.0309 10.8122C28.0721 10.9989 30.0471 11.0354 31.0399 10.8865C31.7911 10.7738 33.1365 10.4372 33.7221 10.2155L34.1495 10.0538L33.7686 9.34149C32.4636 6.9006 30.291 4.54617 27.8748 2.95439C25.624 1.47163 23.0322 0.509369 20.3406 0.157238C19.5291 0.0510894 17.323 -0.0378097 16.8455 0.0163701ZM33.9273 12.5758C29.9485 13.1828 26.331 15.0106 23.4902 17.8495C19.98 21.3574 18.1047 25.8207 17.9682 30.9925L17.9519 31.6101L17.5888 31.0366C15.0708 27.0598 10.4547 24.7488 5.74467 25.107C4.48257 25.203 3.29518 25.4533 2.25193 25.8434L1.8328 26.0001L2.14623 26.6022C2.89833 28.0468 4.27005 29.8382 5.55244 31.0505C8.25605 33.6062 11.6615 35.2708 15.3333 35.8311C16.5596 36.0183 18.8679 36.0574 20.0028 35.9104C22.4009 35.5995 24.4143 34.9834 26.3242 33.9758C33.5289 30.1752 37.3108 22.0926 35.5868 14.1804C35.3051 12.8879 35.1589 12.4647 34.9888 12.4492C34.9091 12.442 34.4315 12.499 33.9273 12.5758Z" fill={color}/>
        </svg>
    );

};

export default SomniaIcon;